import React, {Component} from "react";
import PropTypes from 'prop-types';
import AccordionSection from './accordionSection';
import "./_accordion.scss";

class Accordion extends Component {

    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);

        const openSections = {};

        this.state = {
            openSections,
            loadMore: !!(props.loadmore),
        };
    }

    onClick = label => {
        const {
            state: { openSections },
        } = this;

        const isOpen = !!openSections[label];

        this.setState({
            openSections: {
                [label]: !isOpen
            }
        });
    };

    loadMoreClick = () => {
        this.setState({
            loadMore: !this.state.loadMore,
        });
    }

    render () {

        const {
            onClick,
            loadMoreClick,
            props: { children },
            state: { openSections, loadMore },
        } = this;

        const wrapperClass = (this.props.bodyPadding) ? "accordion-component": "accordion-component no-padding";

        return (
            <div className={wrapperClass}>
                {children.map( (child, index) => (
                    <AccordionSection
                        key={index}
                        isOpen={!!openSections[child.props.label]}
                        label={child.props.label}
                        subtitle={child.props.subtitle}
                        hidden={child.props.hidden}
                        loadMore={this.state.loadMore}
                        onClick={onClick}
                    >
                        {child.props.children}
                    </AccordionSection>
                ))}
                {loadMore && (
                    <div className="accordion-footer">
                        <button className="btn btn-white load-more" onClick={loadMoreClick}>Load More</button>
                    </div>
                )}
            </div>
        )
    }
}

export default Accordion;
