import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const {
            onClick,
            props: {isOpen, label, subtitle},
        } = this;

        const hidden = this.props.hidden && this.props.loadMore ? 'hidden' : '';

        return (
            <>
                <div className={isOpen ? `accordion-item open ${hidden}` : `accordion-item ${hidden}`}>
                    <div className='accordion-header' onClick={onClick} role="presentation">
                        {subtitle ? (
                            <div className="accordion-title">{label} <span className="subtitle">| {subtitle}</span></div>
                        ): (
                            <div className="accordion-title">{label}</div>
                        )}
                        <div className="accordion-arrow">
                            <i className="icon-arrow-down" />
                        </div>
                    </div>
                    <div className="accordion-body">
                        {this.props.children}
                    </div>
                </div>
            </>
        );
    }
}

export default AccordionSection;
