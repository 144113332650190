import React from "react";
import Layout from "~components/base/layout"
import SEO from "~components/base/seo"
import Accordion from "~components/accordion/accordion";
import Products from "~components/products/products";
import TypeAnimation from 'react-type-animation';

const ShelfWebsitesPage = () => (
    <Layout>
        <SEO title="Off-the Shelf Websites"/>
        <section className="page-heading">
            <div className="container">
                <TypeAnimation
                    cursor={false}
                    sequence={['Off-the Shelf', 1500, 'Off-the', 1500, 'Off-the Shelf Websites', 1500]}
                    className={'title'}
                    wrapper="h1"
                />
            </div>
        </section>
        <section>
            <div className="container">
                <Accordion>
                    <div label="Option 1" subtitle="$3,500-$4,750">
                        <p>No commerce, no complex integrations – a website to help you be found, explain what you do
                            and what makes you special. We present you 3 design concepts. You choose 1 We build out your
                            site and you provide the copy. </p>
                        <Products type="slim">
                            <div title="$3,500" id="website-opt1-3500"> </div>
                            <div title="$4,750" id="website-opt1-4750">
                                <p>if you need help with the copy/content</p>
                            </div>
                        </Products>
                    </div>
                    <div label="Option 2" subtitle="$7,500-$10,500">
                        <p>Simple commerce, but no complex CRM or marketing automation or integrations outside of your
                            payment portal. We present you 3 concepts along with a user journey map You choose 1
                            design concept and sign off on the journey. We build.</p>
                        <Products type="slim">
                            <div title="$7,500" id="website-opt2-7500">
                                <p>(you set up your products and upload images)</p>
                            </div>
                            <div title="$10,500" id="website-opt2-10500">
                                <p>(we take care of setting up your products, descriptions and images)</p>
                            </div>
                        </Products>
                    </div>
                    <div label="Option 3" subtitle="$30,000+ (Shopify Site)">
                        <p>You read that right. Shopify is brutal. But it is awesome because you can do everything
                            yourself – and if you are looking to pay someone to manage or build your Shopify site, you
                            are doing it wrong. And if you are going to force us to put you into a technological corner,
                            we need to be compensated for the guilt and stress.</p>
                        <Products type="slim">
                            <div title="$30,000" id="website-opt3-30000"> </div>
                        </Products>
                    </div>
                </Accordion>
            </div>
        </section>
    </Layout>
)

export default ShelfWebsitesPage;
